import $ from 'jquery'

const app = () => {
  let floatable = $('.floatable')
  let toggler = $('.floatable-toggler')
  let button = $('.floatable-button')
  let closer = $('.floatable-close')

  if (!floatable.length) {
    return
  }

  toggler.on('click touch', e => {
    e.preventDefault()
    floatable.toggleClass('expand')
    button.toggleClass('d-none')
  })

  closer.on('click touch', e => {
    e.preventDefault()
    floatable.removeClass('expand')
    button.removeClass('d-none')
  })
}

$(document).ready(app)
